@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed");

.Drawer__Container {
    height: 100%;
    width: 300px;
    background-color: white;
    transition: all 0.4s ease-in-out;
    transform: translate(-300px);
    position: fixed;
    z-index: 13;
    overflow: scroll;
    overflow-x: hidden;
}

.Drawer__Container--isOpen {
    transform: translate(0);
    transition: all 0.4s ease-in-out;
    box-shadow: unset;
    box-shadow: 5px 8px 18px rgb(0, 0, 0.7);
}

.Drawer__Container::-webkit-scrollbar {
    width: 4px;
    height: 0px;
}
.Drawer__Container::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.Drawer__Container::-webkit-scrollbar-thumb {
    background: #888;
}
.Drawer__Container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.Menu_Button {
    display: inline-block;
    position: fixed;
    z-index: 14;
    top: 45%;
    left: 0px;
    cursor: pointer;
    background-color: black;
    width: 45px;
    height: 45px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -khtml-border-radius: 50%;
    border: none;
    outline: none;
    color: #fff;
    font-size: 36px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: all 0.4s ease-in-out;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    animation: change 2s linear infinite;
}

.Menu_Button--isOpen {
    left: 300px;
    transition: all 0.4s ease-in-out;
    transform: scale(1.1);
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}

@keyframes change {
    0% {
        opacity: 0.7;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.7;
    }
}
