.emoji-mart,
.emoji-mart * {
  box-sizing: border-box!important;
  line-height: 1.15!important;
}

.emoji-mart {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif!important;
  font-size: 16px!important;
  display: inline-block!important;
  color: #222427!important;
  border: 1px solid #d9d9d9!important;
  border-radius: 5px!important;
  background: #fff!important;
}

.emoji-mart .emoji-mart-emoji {
  padding: 6px!important;
}

.emoji-mart-bar {
  border: 0 solid #d9d9d9!important;
}
.emoji-mart-bar:first-child {
  border-bottom-width: 1px!important;
  border-top-left-radius: 5px!important;
  border-top-right-radius: 5px!important;
}
.emoji-mart-bar:last-child {
  border-top-width: 1px!important;
  border-bottom-left-radius: 5px!important;
  border-bottom-right-radius: 5px!important;
}

.emoji-mart-anchors {
  display: flex!important;
  flex-direction: row!important;
  justify-content: space-between!important;
  padding: 0 6px!important;
  line-height: 0!important;
}

.emoji-mart-anchor {
  position: relative!important;
  display: block!important;
  flex: 1 1 auto!important;
  color: #858585!important;
  text-align: center!important;
  padding: 12px 4px!important;
  overflow: hidden!important;
  transition: color .1s ease-out!important;
  margin: 0!important;
  box-shadow: none!important;
  background: none!important;
  border: none!important;
}
.emoji-mart-anchor:focus { outline: 0 }
.emoji-mart-anchor:hover,
.emoji-mart-anchor:focus,
.emoji-mart-anchor-selected {
  color: #464646!important;
}

.emoji-mart-anchor-selected .emoji-mart-anchor-bar {
  bottom: 0!important;
}

.emoji-mart-anchor-bar {
  position: absolute!important;
  bottom: -3px!important; left: 0!important;
  width: 100%!important; height: 3px!important;
  background-color: #464646!important;
}

.emoji-mart-anchors i {
  display: inline-block!important;
  width: 100%!important;
  max-width: 22px!important;
}

.emoji-mart-anchors svg,
.emoji-mart-anchors img {
  fill: currentColor!important;
  height: 18px!important;
  width: 18px!important;
}

.emoji-mart-scroll {
  overflow-y: scroll!important;
  overflow-x: hidden!important;
  height: 270px!important;
  padding: 0 6px 6px 6px!important;
  will-change: transform!important; /* avoids "repaints on scroll" in mobile Chrome */
}

.emoji-mart-search {
  margin-top: 6px!important;
  padding: 0 6px!important;
  position: relative!important;
}

.emoji-mart-search input {
  font-size: 16px!important;
  display: block!important;
  width: 100%!important;
  padding: 5px 25px 6px 10px!important;
  border-radius: 5px!important;
  border: 1px solid #d9d9d9!important;
  outline: 0!important;
}

.emoji-mart-search input,
.emoji-mart-search input::-webkit-search-decoration,
.emoji-mart-search input::-webkit-search-cancel-button,
.emoji-mart-search input::-webkit-search-results-button,
.emoji-mart-search input::-webkit-search-results-decoration {
  /* remove webkit/blink styles for <input type="search">
   * via https://stackoverflow.com/a/9422689 */
  -webkit-appearance: none!important;
}

.emoji-mart-search-icon {
  position: absolute!important;
  top: 7px!important;
  right: 11px!important;
  z-index: 2!important;
  padding: 2px 5px 1px!important;
  border: none!important;
  background: none!important;
}

.emoji-mart-category .emoji-mart-emoji span {
  z-index: 1!important;
  position: relative!important;
  text-align: center!important;
  cursor: default!important;
}

.emoji-mart-category .emoji-mart-emoji:hover:before {
  z-index: 0!important;
  content: ""!important;
  position: absolute!important;
  top: 0!important; left: 0!important;
  width: 100%!important; height: 100%!important;
  background-color: #f4f4f4!important;
  border-radius: 100%!important;
}

.emoji-mart-category-label {
  z-index: 2!important;
  position: relative!important;
  position: -webkit-sticky!important;
  position: sticky!important;
  top: 0!important;
}

.emoji-mart-category-label span {
  display: block!important;
  width: 100%!important;
  font-weight: 500!important;
  padding: 5px 6px!important;
  background-color: #fff!important;
  background-color: rgba(255, 255, 255, .95)!important;
}

.emoji-mart-category-list {
  margin: 0!important;
  padding: 0!important;
}

.emoji-mart-category-list li {
  list-style: none!important;
  margin: 0!important;
  padding: 0!important;
  display: inline-block!important;
}

.emoji-mart-emoji {
  position: relative!important;
  display: inline-block!important;
  font-size: 0!important;
  margin: 0!important;
  padding: 0!important;
  border: none!important;
  background: none!important;
  box-shadow: none!important;
}

.emoji-mart-emoji-native {
  font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji"!important;
}

.emoji-mart-no-results {
  font-size: 14px!important;
  text-align: center!important;
  padding-top: 70px!important;
  color: #858585!important;
}
.emoji-mart-no-results-img {
  display: block!important;
  margin-left: auto!important;
  margin-right: auto!important;
  width: 50%!important;
}
.emoji-mart-no-results .emoji-mart-category-label {
  display: none!important;
}
.emoji-mart-no-results .emoji-mart-no-results-label {
  margin-top: .2em!important;
}
.emoji-mart-no-results .emoji-mart-emoji:hover:before {
  content: none!important;
}

.emoji-mart-preview {
  position: relative!important;
  height: 70px!important;
}

.emoji-mart-preview-emoji,
.emoji-mart-preview-data,
.emoji-mart-preview-skins {
  position: absolute!important;
  top: 50%!important;
  transform: translateY(-50%)!important;
}

.emoji-mart-preview-emoji {
  left: 12px!important;
}

.emoji-mart-preview-data {
  left: 68px!important; right: 12px!important;
  word-break: break-all!important;
}

.emoji-mart-preview-skins {
  right: 30px!important;
  text-align: right!important;
}

.emoji-mart-preview-skins.custom {
  right: 10px!important;
  text-align: right!important;
}

.emoji-mart-preview-name {
  font-size: 14px!important;
}

.emoji-mart-preview-shortname {
  font-size: 12px!important;
  color: #888!important;
}
.emoji-mart-preview-shortname + .emoji-mart-preview-shortname,
.emoji-mart-preview-shortname + .emoji-mart-preview-emoticon,
.emoji-mart-preview-emoticon + .emoji-mart-preview-emoticon {
  margin-left: .5em!important;
}

.emoji-mart-preview-emoticon {
  font-size: 11px!important;
  color: #bbb!important;
}

.emoji-mart-title span {
  display: inline-block!important;
  vertical-align: middle!important;
}

.emoji-mart-title .emoji-mart-emoji {
  padding: 0!important;
}

.emoji-mart-title-label {
  color: #999A9C!important;
  font-size: 26px!important;
  font-weight: 300!important;
}

.emoji-mart-skin-swatches {
  font-size: 0!important;
  padding: 2px 0!important;
  border: 1px solid #d9d9d9!important;
  border-radius: 12px!important;
  background-color: #fff!important;
}

.emoji-mart-skin-swatches.custom {
  font-size: 0!important;
  border: none!important;
  background-color: #fff!important;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch {
  width: 16px!important;
  padding: 0 2px!important;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch.selected:after {
  opacity: .75!important;
}

.emoji-mart-skin-swatch {
  display: inline-block!important;
  width: 0!important;
  vertical-align: middle!important;
  transition-property: width, padding!important;
  transition-duration: .125s!important;
  transition-timing-function: ease-out!important;
}

.emoji-mart-skin-swatch:nth-child(1) { transition-delay: 0s }
.emoji-mart-skin-swatch:nth-child(2) { transition-delay: .03s }
.emoji-mart-skin-swatch:nth-child(3) { transition-delay: .06s }
.emoji-mart-skin-swatch:nth-child(4) { transition-delay: .09s }
.emoji-mart-skin-swatch:nth-child(5) { transition-delay: .12s }
.emoji-mart-skin-swatch:nth-child(6) { transition-delay: .15s }

.emoji-mart-skin-swatch.selected {
  position: relative!important;
  width: 16px!important;
  padding: 0 2px!important;
}

.emoji-mart-skin-swatch.selected:after {
  content: ""!important;
  position: absolute!important;
  top: 50%!important; left: 50%!important;
  width: 4px!important; height: 4px!important;
  margin: -2px 0 0 -2px!important;
  background-color: #fff!important;
  border-radius: 100%!important;
  pointer-events: none!important;
  opacity: 0!important;
  transition: opacity .2s ease-out!important;
}

.emoji-mart-skin-swatch.custom {
  display: inline-block!important;
  width: 0!important;
  height: 38px!important;
  overflow: hidden!important;
  vertical-align: middle!important;
  transition-property: width, height!important;
  transition-duration: .125s!important;
  transition-timing-function: ease-out!important;
  cursor: default!important;
}

.emoji-mart-skin-swatch.custom.selected {
  position: relative!important;
  width: 36px!important;
  height: 38px!important;
  padding: 0 2px 0 0!important;
}

.emoji-mart-skin-swatch.custom.selected:after {
  content: ""!important;
  width: 0!important;
  height: 0!important;
}

.emoji-mart-skin-swatches.custom .emoji-mart-skin-swatch.custom:hover {
  background-color: #f4f4f4!important;
  border-radius: 10%!important;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom {
  width: 36px!important;
  height: 38px!important;
  padding: 0 2px 0 0!important;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom.selected:after {
  opacity: .75!important;
}

.emoji-mart-skin-text.opened {
  display: inline-block!important;
  vertical-align: middle!important;
  text-align: left!important;
  color: #888!important;
  font-size: 11px!important;
  padding: 5px 2px!important;
  width: 95px!important;
  height: 40px!important;
  border-radius: 10%!important;
  background-color: #fff!important;
}

.emoji-mart-skin {
  display: inline-block!important;
  width: 100%!important;
  padding-top: 100%!important;
  max-width: 12px!important;
  border-radius: 100%!important;
}

.emoji-mart-skin-tone-1 { background-color: #ffc93a }
.emoji-mart-skin-tone-2 { background-color: #fadcbc }
.emoji-mart-skin-tone-3 { background-color: #e0bb95 }
.emoji-mart-skin-tone-4 { background-color: #bf8f68 }
.emoji-mart-skin-tone-5 { background-color: #9b643d }
.emoji-mart-skin-tone-6 { background-color: #594539 }

/* For screenreaders only, via https://stackoverflow.com/a/19758620 */
.emoji-mart-sr-only {
  position: absolute!important;
  width: 1px!important;
  height: 1px!important;
  padding: 0!important;
  margin: -1px!important;
  overflow: hidden!important;
  clip: rect(0, 0, 0, 0)!important;
  border: 0!important;
}

/*
 * Dark mode styles
 */

.emoji-mart-dark {
  color: #fff!important;
  border-color: #555453!important;
  background-color: #222!important;
}

.emoji-mart-dark .emoji-mart-bar {
  border-color: #555453!important;
}

.emoji-mart-dark .emoji-mart-search input {
  color: #fff!important;
  border-color: #555453!important;
  background-color: #2f2f2f!important;
}

.emoji-mart-dark .emoji-mart-search-icon svg {
  fill: #fff!important;
}

.emoji-mart-dark .emoji-mart-category .emoji-mart-emoji:hover:before {
  background-color: #444!important;
}

.emoji-mart-dark .emoji-mart-category-label span {
  background-color: #222!important;
  color: #fff!important;
}

.emoji-mart-dark .emoji-mart-skin-swatches {
  border-color: #555453!important;
  background-color: #222!important;
}

.emoji-mart-dark .emoji-mart-anchor:hover,
.emoji-mart-dark .emoji-mart-anchor:focus,
.emoji-mart-dark .emoji-mart-anchor-selected {
  color: #bfbfbf!important;
}