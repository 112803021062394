@import "./icons.scss";

.statistic-container > * {
  // Dark Theme
  // @import "./bootstrap-dark.scss";
  // @import "./app-dark.scss";

  // Light Theme
  @import "./bootstrap.scss";
  @import "./app.scss";

  // // RTL Mode
  // @import "./rtl/bootstrap-rtl";
  // @import "./rtl/components-rtl";
  // @import "./rtl/float-rtl";
  // @import "./rtl/general-rtl";
  // @import "./rtl/pages-rtl";
  // @import "./rtl/plugins-rtl";
  // @import "./rtl/spacing-rtl";
  // @import "./rtl/structure-rtl";
  // @import "./rtl/text-rtl";

  #sidebar-menu {
    ul {
      li {
        a {
          i {
            min-width: 2rem;
          }
        }
      }
    }
  }
  .active-contact-list {
    background-color: #fff;
    border-color: transparent;
    box-shadow: 0 0.75rem 1.5rem #12263f08;
    display: block;
    padding: 14px 16px;
    color: #74788d;
    transition: all 0.4s;
    border-top: 0px solid #eff2f7;
    border-radius: 4px;
  }
  .noti-icon .badge {
    left: 23px;
  }
  .dataTables_filter,
  .dataTables_paginate {
    float: right;
  }
  .rdw-editor-main {
    border: 1px solid $gray-300;
    height: 239px;
  }
  .dz-message {
    text-align: center;
    padding: 30px;
  }
  // kanban board
  .react-kanban-column {
    height: 100%;
    min-height: 28px;
    display: inline-block;
    padding: 15px;
    border-radius: 2px;
    background-color: rgb(238, 238, 238);
    margin: 5px;
    vertical-align: top;
  }
  .fcYNFc,
  .sc-AxmLO.gmtmqV {
    background-color: $card-bg !important;
    margin: 0 20px 0 0 !important;
  }
  .task-box {
    border: 1px solid $border-color;
  }
  .react-datepicker-wrapper {
    width: 100% !important;
  }
  .ReactModal__Overlay {
    z-index: 1001 !important;
  }
  .external-event {
    &:hover {
      cursor: pointer;
    }
  }
  a:hover {
    cursor: pointer;
  }
  .rating-container {
    background-color: transparent !important;
  }
  .input-group-append {
    z-index: 0;
  }
  .input-color {
    color: $input-color !important;
  }
  .sketch-picker {
    position: absolute;
    z-index: 1;
  }
  .rangeslider__fill {
    background-color: $primary !important;
  }

  // .form-check-input {
  //   position: absolute !important;
  //   left: 0 !important;
  //   z-index: -1 !important;
  //   width: 1rem !important;
  //   height: 1.10938rem !important;
  //   opacity: 0 !important;
  // }

  .flatpickr-months .flatpickr-month {
    background: $primary !important;
  }
  .flatpickr-weekdays {
    background: $primary !important;
  }
  span.flatpickr-weekday {
    background: $primary !important;
  }
  .flatpickr-current-month .flatpickr-monthDropdown-months {
    background: $primary !important;
  }
  span.flatpickr-weekday {
    color: $white !important;
  }
  .flatpickr-day.selected {
    background: $primary !important;
  }

  //chat style dot
  // .conversation-list .dropdown .dropdown-toggle{
  //   font-size: 18px !important;
  //   padding: 4px !important;
  //   color: #74788d !important;
  // }

  //dropdown
  .dropdown-menu.dropdown-menu-right {
    right: 0 !important;
    left: auto !important;
  }

  .bs-select select {
    width: auto !important;
  }

  //File-manager
  .file-manager {
    .table td {
      padding: 0.35rem;
    }
  }

  //Authenication carousel
  .slider_css {
    .slide {
      background: none !important;
    }

    // .control-dots {
    //   margin-top: 35px !important;
    // }

    .carousel-status,
    .control-next,
    .control-prev {
      display: none !important;
    }
  }
  .carousel {
    .control-dots {
      margin: -5px 0px;
    }
    .dot {
      background: #556ee6 !important;
      width: 9px !important;
      height: 9px !important;
    }
  }

  //Two verification Page
  .verification {
    input {
      &:focus {
        border: none;
        outline: none !important;
      }
    }
  }

  @media (max-width: 768px) {
    .verification {
      input {
        width: 40px !important;
      }
    }
  }

  @media (max-width: 320px) {
    .verification {
      input {
        width: 30px !important;
        height: 30px !important;
        font-size: 15px !important;
      }
    }
  }

  //Two verification Page - 2
  .verification-2 {
    input {
      &:focus {
        border: none;
        outline: none !important;
      }
    }
  }

  @media (max-width: 768px) {
    .verification-2 {
      input {
        width: 40px !important;
        font-size: 14px;
      }
    }
  }

  @media (max-width: 320px) {
    .verification-2 {
      input {
        width: 30px !important;
        height: 35px !important;
        font-size: 15px !important;
      }
    }
  }

  //Calendar
  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    padding: 5px !important;
    font-size: 12px !important;
    margin-right: 2px;
  }

  //Chat Scrollbar
  .ps__rail-y {
    z-index: 999999 !important;
  }
  .close {
    background: transparent;
    border: 0;
    font-size: 10px;
    padding: 1.35rem 1.25rem;
    background: transparent escape-svg($btn-close-bg-dark) center /
      $btn-close-width auto no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.5;
    width: 1em;
    height: 1em;
    z-index: 2;
    span {
      display: none;
    }
  }

  .card-columns {
    column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
    grid-column-gap: 24px;
    column-gap: 24px;
    orphans: 1;
    widows: 1;
  }

  table {
    height: auto !important;
    display: flex;
    flex-direction: column;
    display: table !important;
  }

  thead:nth-child(2) {
    display: none !important;
  }

  .btn-green-add {
    background-color: #556ee6;
    border-radius: 15px;
    padding: 5px;
    font-size: 13px;
    color: #fff;
    border: 0;
  }
  .enabled-survey {
    background-color: #8bc34a;
    color: white;
    border-radius: 19px;
    padding: 0px 6px;
  }
  .disabled-survey {
    background-color: #ff5722;
    color: white;
    border-radius: 19px;
    padding: 0px 6px;
  }
  .div-questions {
    display: flex;
    align-items: center;
  }
}
